import { openDocument } from './editors/Editors';
import Noty from 'noty';
import 'noty/lib/noty.css';
import 'noty/lib/themes/relax.css';
import { rest } from '../../auto/js/services';

export const api = {
	rest: rest,
	wm: {
		views: new Map(),
		openDocument: openDocument,
		notify: (data) => new Noty(data).show()
	}
};
