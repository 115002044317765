import React from 'react';

import styled from 'styled-components';
import { OPEN_VIEW_EVENT, CLOSE_VIEW_EVENT } from '../../../auto/js/events/Gui';

import { RibbonTab } from '../../../auto/js/widgets';

import { ToolBar } from './ToolBar';

import '@trendmicro/react-breadcrumbs/dist/react-breadcrumbs.css';
import '@trendmicro/react-buttons/dist/react-buttons.css';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/css/bootstrap.min.css";
import RGL, { WidthProvider } from 'react-grid-layout';
import { EditorTitleBar } from './EditorTitleBar';

import "./grid.css";

const ReactGridLayout = WidthProvider(RGL);

const availableHandles = ["s", "e", "se"];

function getStateFromLS() {
  let state;
  if (global.localStorage) {
    try {
      state = JSON.parse(global.localStorage.getItem("tennex-gui")) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return state;
}

function saveStateToLS(state) {
  if (global.localStorage) {
    global.localStorage.setItem(
      "tennex-gui",
      JSON.stringify(state)
    );
  }
}

const Main = styled.main`
    position: relative;
    overflow: hidden;
    transition: all .15s;
    height: calc(100vh - 175px);
	overflow-y: auto;
	direction: ltr;
	margin-top: 20px;
	margin-bottom: 20px;
`;

const onWindowClose = (uuid, onClose) => {
	if (onClose)
		return () => onClose().then(CLOSE_VIEW_EVENT.publish(uuid));
	return () => CLOSE_VIEW_EVENT.publish(uuid);
}

export class MainPage extends React.Component {
	static defaultProps = {
		className: "layout",
		items: 20,
		rowHeight: 30,
		cols: 12
	};

	constructor(props) {
		super(props);
 		this.state = getStateFromLS();
		this.x=0;
		if (!this.state) {
			this.state = {
				layout: []
			}
		} else if (!this.state.layout)
			this.state.layout = [];
		OPEN_VIEW_EVENT.subscribe((v) => this.openView(v));
		CLOSE_VIEW_EVENT.subscribe((uuid) => this.closeView(uuid));
    }

	register = (v) => {
		window.tennex.wm.views.set(v.uuid, v);
	}
	
	unregister = (uuid) => {
		window.tennex.wm.views.delete(uuid);
	}

	openView = (v) => {
		if (!window.tennex.wm.views.has(v.uuid)) {
			this.register(v);
			this.setState({
				layout: [ { i: v.uuid, x: this.x, y:  -1, w: 4, h: 4, resizeHandles: availableHandles }, ...this.state.layout ]
			});
			this.x+=4;
			this.x%=12;
		}
	}
	
	closeView = (uuid) => {
		if (window.tennex.wm.views.has(uuid)) {
			this.unregister(uuid);
			this.setState({
				layout: this.state.layout.filter(e => e.i !== uuid)
			});
		}
	}
	
	buildWorkspace = () => {
		let items = [];
		for (const [uuid, v] of window.tennex.wm.views.entries()) {
			items.push(
				<div key={uuid}>
					<EditorTitleBar uuid={uuid} onClose={onWindowClose(uuid, v.onClose)}/>
					{v.view()}
				</div>);
		}
		return items;
	}
	
	onLayoutChange = (layout) =>  {
		this.setState({ layout });
	    saveStateToLS({ layout });
	}

	render() {
		return (
			<div>
				<ToolBar languages={this.props.languages}/>
				<RibbonTab/>
				<Main>
					<ReactGridLayout
						layout={this.state.layout}
						onLayoutChange={this.onLayoutChange}
						onDragStop={this.onLayoutChange} //workaround, since layoutchange isn't called on dragstop
						useCSSTransforms={true}
						draggableHandle=".drag-handle"
					>
							{this.buildWorkspace()}
					</ReactGridLayout>
				</Main>
			</div>
		);
	}
}